<template>
    <div>

        <div class="mt-4">
            <div class="container">
                <h4 class="text-center mb-4 fw-600">Activator Rankings</h4>

                
            <div class="row">

                <div class="col-md-4 col-lg-4 col-4 col-sm-4">

                    <h5 class="text-center pb-2">
                      Activator Name
                    </h5>
                </div>
                <div class="col-md-4 col-lg-4 col-4 col-sm-4" >
                        <h5 class="text-center pb-2">
                            Active Users Registered
                        </h5>
            </div>
                <div class="col-md-4 col-lg-4 col-4 col-sm-4" >
                        <h5 class="text-center pb-2">
                          Total Users Registered
                        </h5>
            </div>
                </div>


            <div class="row" v-for="rank in rankings" :key="rank.id">

                <div class="col-md-4 col-lg-4 col-4 col-sm-4 pt-2 pb-2" style="border-top: 1px solid #f2f2f2">

                    <p class="text-center">
                        {{rank.first_name}}
                    </p>
                </div>
                <div class="col-md-4 col-lg-4 col-4 col-sm-4 pt-2 pb-2" style="border-top: 1px solid #f2f2f2">
                        <p class="text-center">
                            {{rank.active_ref_count}}
                        </p>
            </div>
                <div class="col-md-4 col-lg-4 col-4 col-sm-4 pt-2 pb-2" style="border-top: 1px solid #f2f2f2">
                        <p class="text-center">
                            {{rank.total_ref_count}}
                        </p>
            </div>
        
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import http from "../../http/index";
import api from "../../apis/index";
export default {

data(){

    return {

            rankings:{}

    }
},

methods:{

    getActivatorRanks() {
   
            let vm = this
            // localStorage.setItem("userEmail", state.signUp.email);

            http
                .get(api.activatorank)
                .then(response => {
            
                    this.$helpers.log(response, 'rank');

                    vm.rankings = response.data.data

                })
                .catch(error => {
              
                             this.$helpers.log(error.response);
                });

        },
},

mounted(){

    this.getActivatorRanks();
}
}
</script>

<style>

</style>
