<template>
    <ActivatorStandV/>
</template>

<script>
import ActivatorStandV from '../../components/auth/ActivatorStand'
export default {

    components: {
        ActivatorStandV
    }
}
</script>

<style>

</style>
